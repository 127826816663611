import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias:'/dashboard',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/rh-loans',
    name: 'ViewLoans',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ViewLoans" */ '../views/Loans.vue')
  },
  {
    path: '/report-transaction-payroll',
    name: 'ReportByTransaction',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ReportByTransaction" */ '../views/ReportTransaction.vue')
  },
  {
    path: '/receipt-payment',
    name: 'ReceiptPayment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ReceiptPayment" */ '../views/ReceiptPayment.vue')
  },
  {
    path: '/barcode',
    name: 'BarCode',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "BarCode" */ '../views/Barcode.vue')
  },
  {
    path: '/ledger-accounts',
    name: 'ledgerAccounts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ledgerAccounts" */ '../views/LedgerAccounts.vue')
  },
  {
    path: '/transactions',
    name: 'AccountTransactions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AccountTransactions" */ '../views/AccountTransactions.vue')
  },
  {
    path: '/number-transactions/:url',
    name: 'NumberAccTrans',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "NumberAccTrans" */ '../views/NumberAccTrans.vue')
  },
  {
    path: '/general-ledger',
    name: 'GeneralLedger',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GeneralLedger" */ '../views/GeneralLedger.vue')
  },
  {
    path: '/create-account',
    name: 'AccountModal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AccountModal" */ '../views/AccountModal.vue')
  },
  {
    path: '/runlevel',
    name: 'Runlevel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Runlevel" */ '../views/Runlevel.vue')
  },
  {
    path: '/trial-balance',
    name: 'TrialBalance',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "TrialBalance" */ '../views/TrialBalance.vue')
  },
  {
    path: '/status-of-project',
    name: 'StatusOfProject',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "StatusOfProject" */ '../views/StatusOfProject.vue')
  },
  {
    path: '/account-entry',
    name: 'AccountEntry',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AccountEntry" */ '../views/AccountEntry.vue')
  },
  {
    path: '/income-statement',
    name: 'IncomeStatement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "IncomeStatement" */ '../views/IncomeStatement.vue')
  },
  {
    path: '/balance-sheet',
    name: 'BalanceSheet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "BalanceSheet" */ '../views/BalanceSheet.vue')
  },
  {
    path: '/forms-create',
    name: 'CreateForms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "CreateForms" */ '../views/FormsCreate.vue')
  },
  {
    path: '/template-account',
    name: 'TemplateAccount',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "TemplateAccount" */ '../views/TemplateAccount.vue')
  },
  {
    path: '/consolidated',
    name: 'Consolidated',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Consolidated" */ '../views/Consolidated.vue')
  },
  {
    path: '/general-ledger-details',
    name: 'GeneralLedgerDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Consolidated" */ '../views/GeneralLedgerDetails.vue')
  },
  // {
  //   path: '/settings',
  //   name: 'Settings',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "Settings" */ '../views/Settings.vue')
  // },
  // {
  //   path: '/periods',
  //   name: 'Periods',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "Periods" */ '../views/Periods.vue')
  // },
  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
