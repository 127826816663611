<template>
<!-- v-if="isDesktopOrTablet" -->
	<div
	v-if="mostrarDiv"
	ref="sideBar"
	class="overflow-y-auto"
	>
		<!-- Desktop sidebar -->
		<aside
		
			class="z-20 hidden w-64 overflow-y-auto bg-white dark:bg-gray-800 md:block flex-shrink-0 rounded-md"
		>
			<div class="py-4 text-gray-500 dark:text-gray-400 text-left"> 
				
				<div class="flex justify-center">

					<img v-if="imgLogoURL !== undefined " :src="imgLogoURL" width="65" alt="" >
					<!-- Windmill -->
				</div>
				<ul class="mt-6">
					<li class="relative px-6 py-3 bg-green-500">
						<div class="text-xs font-semibold text-white">
							Commit Date: {{commitDate}}
						</div>
						<div class="text-xs font-semibold text-white">
							branch: {{branch}}
						</div>
						<div class="text-xs font-semibold text-white">
							Hash: {{commitHash}}
						</div>
						<div class="text-xs font-semibold text-white">
							version: {{version}}
						</div>
					</li>
				</ul>
				<ul class="mt-6">
					<li class="relative px-6 py-3">
						<span
							class="absolute inset-y-0 left-0 w-1 bg-blue-500 rounded-tr-lg rounded-br-lg"
							aria-hidden="true"
						></span>
						<router-link to="/"
							class="inline-flex items-center w-full text-sm font-semibold text-gray-800 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100"
							
						>
							<i class="icon-gauge mb-1"></i>
							<span class="ml-4">Dashboard</span>
						</router-link>
					</li>
				</ul>
				<ul v-if="getPermissions('D')">
					<li class="relative px-6 py-3">
						<button
							class="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
							@click="togglePagesMenu('isPagesMenuOpenLoans')"
							aria-haspopup="true"
						>
							<span class="inline-flex items-center">
								<i class="icon-users-1 mb-1"></i>
								<span class="ml-4">Recursos Humanos</span>
							</span>
							<svg
								class="w-4 h-4"
								aria-hidden="true"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path
									fill-rule="evenodd"
									d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
									clip-rule="evenodd"
								></path>
							</svg>
						</button>
						<template v-if="isPagesMenuOpenLoans">
							<ul
								x-transition:enter="transition-all ease-in-out duration-300"
								x-transition:enter-start="opacity-25 max-h-0"
								x-transition:enter-end="opacity-100 max-h-xl"
								x-transition:leave="transition-all ease-in-out duration-300"
								x-transition:leave-start="opacity-100 max-h-xl"
								x-transition:leave-end="opacity-0 max-h-0"
								class="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900"
								aria-label="submenu"
							>	
								<li
									class="transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
								>
									<h2 class="w-full text-lg font-bold text-gray-800">Modulos</h2>
								</li>
								<li
									class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
									>
									<router-link to="/rh-loans" class="w-full">Prestamos a personal</router-link>
								</li>
								
								<li
									class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
									>
									<router-link to="/hr-vacation" class="w-full">Vacaciones</router-link>
								</li>
								<li
									class="transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
								>
									<h2 class="w-full text-lg font-bold text-gray-800">Reportes</h2>
								</li>
								<li
									class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
								>
									<router-link to="/report-transaction-payroll" class="w-full">Por Transacci&oacute;n</router-link>
								</li>
								<li
									class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
								>
									<router-link to="/receipt-payment" class="w-full">Recibo de pago</router-link>
								</li>
								<li
									class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
								>
									<router-link to="/barcode" class="w-full">BarCode</router-link>
								</li>
								
							</ul>
						</template>
					</li>
				</ul>
				<ul v-if="getPermissions('accounting')">
					<li class="relative px-6 py-3">
						<button
							class="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
							@click="togglePagesMenu('isPagesMenuOpenLoans1')"
							aria-haspopup="true"
						>
							<span class="inline-flex items-center">
								<i class="icon-calc mb-1"></i>
								<span class="ml-4">Contabilidad</span>
							</span>
							<svg
								class="w-4 h-4"
								aria-hidden="true"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path
									fill-rule="evenodd"
									d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
									clip-rule="evenodd"
								></path>
							</svg>
						</button>
						<template v-if="isPagesMenuOpenLoans1">
							<ul
								x-transition:enter="transition-all ease-in-out duration-300"
								x-transition:enter-start="opacity-25 max-h-0"
								x-transition:enter-end="opacity-100 max-h-xl"
								x-transition:leave="transition-all ease-in-out duration-300"
								x-transition:leave-start="opacity-100 max-h-xl"
								x-transition:leave-end="opacity-0 max-h-0"
								class="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900"
								aria-label="submenu"
							>	
								<li
									class="transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
								>
									<h2 class="w-full text-lg font-bold text-gray-800">Modulos</h2>
								</li>
								
								<li v-if="getPermissions('accounting.account.index')"
									class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
									
								>
									<router-link to="/general-ledger" class="w-full">Plan de cuenta</router-link>
								</li>
								<li v-if="getPermissions('accounting.entries.store')"
									class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
									
								>
									<router-link to="/transactions" class="w-full">Asientos Contables</router-link>
								</li>
								
								<li	v-if="getPermissions('accounting.account.create')" class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
									<div 
									class="" 
									>
									<!-- @click="handleIconClick" -->
										<AccountModal id="create-account" title="Crear Cuenta"></AccountModal>
									</div>
								</li>
								
								<li
									class=" pt-3 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
								>
									<hr>
								</li>

								<li
									class="transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
								>
									<h2 class="w-full text-lg font-bold text-gray-800">Reportes</h2>
								</li>
								<li v-if="getPermissions('accounting.report.journal')"	class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" >
									<router-link to="/account-entry" class="w-full">Libro Diario</router-link>
								</li>
								<li v-if="getPermissions('accounting.report.ledger')"	class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" >
									<router-link to="/ledger-accounts" class="w-full">Detalle de Cuentas</router-link>
								</li>
								<li v-if="getPermissions('accounting.report.ledger')"	class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" >
									<router-link to="/general-ledger-details" class="w-full">Libro Mayor</router-link>
								</li>
								<li v-if="getPermissions('accounting.report.trialbalance')"
									class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" >
									<router-link to="/trial-balance" class="w-full">Balance de comprobaci&oacute;n</router-link>
								</li>
								<li v-if="getPermissions('accounting.report.incomestatement')"
									class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" >
									<router-link to="/income-statement" class="w-full">Estado de resultado</router-link>
								</li>
								<li v-if="getPermissions('accounting.report.balancesheet')"
									class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" >
									<router-link to="/balance-sheet" class="w-full">Balance General</router-link>
								</li>
								<li 
									class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" >
									<router-link to="/consolidated" class="w-full">Consolidado</router-link>
								</li>
							</ul>
							<ul v-if="getPermissions('accounting.reportext')"
								x-transition:enter="transition-all ease-in-out duration-300"
								x-transition:enter-start="opacity-25 max-h-0"
								x-transition:enter-end="opacity-100 max-h-xl"
								x-transition:leave="transition-all ease-in-out duration-300"
								x-transition:leave-start="opacity-100 max-h-xl"
								x-transition:leave-end="opacity-0 max-h-0"
								class="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900"
								aria-label="submenu"
								>
								<h2 class="w-full text-lg font-bold text-gray-800">Reportes Externos</h2>
								<li v-if="getPermissions('accounting.reportext.runlevel')" class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" >
									<router-link to="/runlevel" class="w-full">Nivel de ejecucion</router-link>
								</li>
								<li v-if="getPermissions('accounting.reportext.statusofproject')"
									class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" 
								>
									<router-link to="/status-of-project" class="w-full">Estado de proyecto</router-link>
								</li>
							</ul>
							<ul v-if="getPermissions('accounting.reportext')"
								x-transition:enter="transition-all ease-in-out duration-300"
								x-transition:enter-start="opacity-25 max-h-0"
								x-transition:enter-end="opacity-100 max-h-xl"
								x-transition:leave="transition-all ease-in-out duration-300"
								x-transition:leave-start="opacity-100 max-h-xl"
								x-transition:leave-end="opacity-0 max-h-0"
								class="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900"
								aria-label="submenu"
								>
								<h2 class="w-full text-lg font-bold text-gray-800">Configuración</h2>
								<li  class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" >
									<router-link to="/template-account" class="w-full">Template Accounts</router-link>
								</li>
								<li  class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" >
									<router-link to="/settings" class="w-full">Settings</router-link>
								</li>
								<li v-if="getPermissions('accounting.reportext.statusofproject')"
									class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" 
								>
									<router-link to="/periods" class="w-full">Periodos</router-link>
								</li>
							</ul>
							<ul v-if="getPermissions('accounting.reportext')"
								x-transition:enter="transition-all ease-in-out duration-300"
								x-transition:enter-start="opacity-25 max-h-0"
								x-transition:enter-end="opacity-100 max-h-xl"
								x-transition:leave="transition-all ease-in-out duration-300"
								x-transition:leave-start="opacity-100 max-h-xl"
								x-transition:leave-end="opacity-0 max-h-0"
								class="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900"
								aria-label="submenu"
								>
								<h2 class="w-full text-lg font-bold text-gray-800">Facturacion</h2>
								<li  class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" >
									<router-link to="/quick-invoice" class="w-full">Quick Invoice</router-link>
								</li>
								
							</ul>
						</template>
					</li>
				</ul>
				<ul v-if="getPermissions('formgenerator')">
					<li class="relative px-6 py-3">
						<button
							class="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
							@click="togglePagesMenu('isPagesMenuOpenForm')"
							aria-haspopup="true"
						>
							<span class="inline-flex items-center text-left">
								<i class="icon-users-1 mb-1"></i>
								<span class="ml-4">Generador de formulario</span>
							</span>
							<svg
								class="w-4 h-4"
								aria-hidden="true"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path
									fill-rule="evenodd"
									d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
									clip-rule="evenodd"
								></path>
							</svg>
						</button>
						<template v-if="isPagesMenuOpenForm">
							<ul
								x-transition:enter="transition-all ease-in-out duration-300"
								x-transition:enter-start="opacity-25 max-h-0"
								x-transition:enter-end="opacity-100 max-h-xl"
								x-transition:leave="transition-all ease-in-out duration-300"
								x-transition:leave-start="opacity-100 max-h-xl"
								x-transition:leave-end="opacity-0 max-h-0"
								class="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900"
								aria-label="submenu"
							>	
								<li
									class="transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
								>
									<h2 class="w-full text-lg font-bold text-gray-800">Modulos</h2>
								</li>
								<li v-if="getPermissions('formgenerator.create')"
									class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" 
								>
									<router-link to="/forms-create" class="w-full">Crear Formulario</router-link>
								</li>
								
								<li v-if="getPermissions('formgenerator.list')"
									class="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" 
								>
									<router-link to="/" class="w-full">Formularios</router-link>
								</li>
							</ul>
						</template>
					</li>
				</ul>
			</div>
		</aside>
		<!-- Mobile sidebar -->
		<!-- Backdrop -->
	</div>
</template>

<script>
import AccountModal from "../../views/AccountModal.vue";
import { mapState } from 'vuex'
import packageJson from '../../../package.json';
import EventBus from '../content/content-acc/EventBus'; // Importar la función global desde el archivo store.js

export default {
	name: "SideBar",
	data() {
		return {
			isPagesMenuOpenLoans: false,
			isPagesMenuOpenLoans1: false,
			isPagesMenuOpenForm: false,
			isPagesMenuOpen: false,
			imgLogoURL: undefined,
			commitDate: '',
			branch: '',
			commitHash: '',
			version: '',
			mostrarDiv: true, // Variable global para mostrar u ocultar el div
			// isDesktopOrTablet: true
		};
	},
	mounted() {
		this.commitDate = packageJson.commitDate.substring(0, 19);
		this.branch = packageJson.branch;
		this.commitHash = packageJson.commitHash;
		this.version = packageJson.version;

		console.log(`commitDate: ${this.commitDate}`);
		console.log(`branch: ${this.branch}`);
		console.log(`commitHash: ${this.commitHash}`);
		console.log(`version: ${this.version}`);
		let logo = this.user.logo
		this.imgLogoURL = window.location.origin + '/' + logo	
		
		

		// this.getInfoPackageJson()

		// window.addEventListener('resize', this.checkViewport);
    	// this.checkViewport();
	},
	computed: {
		...mapState(['user']),

	},
	methods: {

	// 	checkViewport() {
    //   this.isDesktopOrTablet = window.innerWidth >= 768; // Cambiar el valor de 768 según tus necesidades
    // },

	handleIconClick() {
		this.mostrarDiv = false;
		EventBus.$emit('mi-variable-global',[true] )
    },
		toggleSideMenu() {
			this.isSideMenuOpen = !this.isSideMenuOpen;
		},
		togglePagesMenu(open = '') {
			switch (open) {
				case 'isPagesMenuOpenLoans':
					this.isPagesMenuOpenLoans = !this.isPagesMenuOpenLoans;
					break;
				case 'isPagesMenuOpenLoans1':
					this.isPagesMenuOpenLoans1 = !this.isPagesMenuOpenLoans1;
					break;
				case 'isPagesMenuOpenForm':
					this.isPagesMenuOpenForm = !this.isPagesMenuOpenForm;
					break;
			
				default:
					this.isPagesMenuOpen = !this.isPagesMenuOpen;
					break;
			}
			
		},
		getDataLogo() {
			
		},
		async getInfoPackageJson(){

			// const packageJson = fs.readJsonSync('./package.json');
			
			// // Obtiene el nombre y la versión del paquete
			// const { name, version } = packageJson;

			// // Muestra la información del paquete en la consola
			// console.log(`Nombre: ${name}`);
			// console.log(`Versión: ${version}`);
		},
		getPermissions(permission) {
			let listPesmissions = this.user.permissions
			for (let valuePermission of listPesmissions) {
                    // console.log(valuePermission.name)
                    if(valuePermission.name.indexOf(permission) !== -1){
                        return true;
                    }
                }
			// console.log(this.user.permissions)
		},
		ocultarDiv() {
			EventBus.$emit('ocultar-div'); // Emitir el evento para ocultar el div
		},
	},
	

	components: {
		AccountModal
	},

	created() {
		EventBus.$on('ocultar-div', () => {
			this.mostrarDiv = !this.mostrarDiv; // Cambiar el valor de la variable al recibir el evento
    });
},

};

</script>

<style lang="scss" scoped>
</style>