import Axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";


Vue.use(Vuex)

let access_token = window.localStorage.getItem('access_token')
let user = window.localStorage.getItem('user')
let year = window.localStorage.getItem('year')
let accTemp = window.localStorage.getItem('accTemp')
let accTransResul = window.localStorage.getItem('accTransResul')

export default new Vuex.Store({
	state: {
		access_token: access_token ? access_token : null,
		user: user ?  JSON.parse(user) : null,
		year: year ?  JSON.parse(year) : null,
		loaded: false,
		accTemp: accTemp ? JSON.parse(accTemp) : [],
		accTransResul: accTransResul ? JSON.parse(accTransResul) : [],
		// viewLogin: false
	},
	mutations: {
		getUserToken(state, params){
			state.access_token = params[0].access_token
			state.user = params[0].user
			// console.log('params'); 	
			// console.log(params);
			// state.yourCart.push(oSelectProduct)
			// state.user.push(params)
			let param1 = JSON.stringify(state.access_token);
			let param2 = JSON.stringify(state.user);
			let param3 = JSON.stringify(params);
			let yearActive = JSON.stringify(params[1]); // en esta posicion viene el año en curso de la empresa por defecto del usuario logueado
			localStorage.setItem('access_token',param1)
			localStorage.setItem('user',param2)
			localStorage.setItem('year', yearActive)
			localStorage.setItem('periodClose', param3)
			
		},
		async checkSession(state){
			
			const URL = `${process.env.VUE_APP_URL}user-session`
			
			let access_token = window.localStorage.getItem('access_token')
			if (access_token !== null) {
				access_token = access_token.replace(/['"]+/g, '')
			}
			const fetchConfig = {
				headers: {
					Accept : 'application/json',
					"Content-Type": 'application/json',
					Authorization : `Bearer ${access_token}`	
				}
			}
			// verifico si la informacion del localStorage esta vacia
			if (access_token !== null ) {
				// console.log(access_token)
				
				try {
					// si hay informacion en el storage, envio el tocken al backend para verificar que es un tocken valido
					let res = await Axios.get(URL,fetchConfig)
					
					if (res.statusText === 'OK') {
						state.loaded = true
						// state.viewLogin = false
					}
				} catch (error) {
					
					if (Object.keys(error).length > 0) {
						router.push({ name: "Login", path: "/login" });
					}
					
				}
				
			} else {
				// state.viewLogin = true
				router.push({ name: "Login", path: "/login" });
			}			

		},
		changeContryAndCompany(state, obj){ // obj = [0 country, 1 company]
			// console.log(obj)
			// return
			state.user.countryId 		 = obj[0] //countryid
			state.user.companyId 		 = obj[1] //companyid
			state.user.country			 = obj[2]
			state.user.color			 = obj[3]
			state.user.companyAddress    = obj[4]
			state.user.companyName 		 = obj[5]
			state.user.companyNumber 	 = obj[6]
			state.user.logo 			 = obj[7]

			let param = JSON.stringify(state.user);
			localStorage.setItem('user',param)
			localStorage.removeItem('accTemp')
			localStorage.removeItem('accTransResul')
			
			window.location.reload()
			// console.log(obj)
			// console.log(state.user)
		},
		changeYearCompany(state, obj){ // obj = [0 country, 1 company]
			// console.log(obj)
			// return
			state.year 		 = obj[0] //year selected
			let param = JSON.stringify(state.year);
			localStorage.setItem('year',param)
			
			window.location.reload()
		},
		changeAccTemp(state, oSelectAcc){ 
			console.log('changeAccTemp')
			console.log(oSelectAcc)
			let OselectAcc = JSON.stringify(oSelectAcc)
			localStorage.setItem('accTransResul', OselectAcc)
			
			return
			state.accTemp.push(oSelectAcc)
			state.accTransResul = oSelectAcc[9]
			let param = JSON.stringify(state.accTemp);
			let accTransResul = JSON.stringify(state.accTransResul);
			console.log('state.accTemp')
			console.log(state.accTemp)
			localStorage.setItem('accTemp',param)
		},
		deleteTransactionTemp(state,index){
			state.accTemp.splice(index, 1)
			let param = JSON.stringify(state.accTemp);
			// console.log(param)
			localStorage.setItem('accTemp',param)
			// console.log(this.objTransaction)
			const DEBIT = state.accTemp.map(item => item[7]).reduce((prev, curr) => prev + curr, 0);
			// console.log(DEBIT);
			const CREDIT = state.accTemp.map(item => item[8]).reduce((prev, curr) => prev + curr, 0);
			
			let result = DEBIT - CREDIT
			
			let accTransResul = JSON.stringify(result)
			state.accTransResul = accTransResul
			localStorage.setItem('accTransResul', accTransResul)
			console.log(result);
		},
	},
	actions: {
	},
	modules: {
	}
})
